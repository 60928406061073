import React from "react"

const FooterAr = props => {
  return (
    <footer id="contact">
      <section className="flex top" id="footer">
        <div className="container">
          <div className="row" id="contact">
            <div className="col-md-12">
              <ul className="footer">
                <li>
                  <img
                    src="/images/solitaire-ksa.png"
                    alt="Solitaire Riyadh"
                    className="logo"
                  />
                </li>
                <li>
                  <span>
                    الرياض 13315-4084 ص.ب. 10440، المملكة العربية السعودية،966
                    920020049 +
                  </span>
                  <a
                    href="https://goo.gl/maps/SFUhMfMqP5H8572s5"
                    className="location"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    انقر هنا لموقع صالة العرض
                  </a>
                </li>
                <li>حقوق الطبع والنشر 2021</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default FooterAr
