import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
// import { MenuContext } from "../context/MenuContext"
import Carousel from "./Carousel"

const AboutAr = () => {
  // const menuContext = useContext(MenuContext)
  // const { locale } = menuContext
  // console.log(locale)
  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(databaseId: { eq: 119 }) {
            title
            content
            featuredImage {
              node {
                ...fragmentImage
              }
            }
            ACF_Gallery {
              gallery {
                image {
                  ...fragmentImage
                }
              }
            }
            seo {
              ...WpYoastSEO
            }
          }
        }
      `}
      render={data => {
        let {
          wpPage: {
            title,
            content,
            ACF_Gallery: { gallery },
          },
        } = data
        return (
          <section id="about">
            <div className="container">
              <div className="row main">
                <div className="col-md-5 content">
                  <div className="eyebrow underline">
                    {title && parse(title)}
                  </div>
                  <div>{content && parse(content)}</div>
                </div>
                <div className="col-md-7 carousel p-lr-0">
                  <Carousel data={gallery} cssClass="about-carousel" />
                </div>
              </div>
              <div className="row bg"></div>
            </div>
          </section>
        )
      }}
    ></StaticQuery>
  )
}

export default AboutAr
