import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import AboutAr from "../../components/AboutAr"
import ExpressAr from "../../components/ExpressAr"
import FeaturesAr from "../../components/FeaturesAr"
import FooterAr from "../../components/Footer/FooterAr"
import HeroAr from "../../components/HeroAr"
import Layout from "../../components/Layouts/layout"
import LocationAr from "../../components/LocationAr"
import SEO from "../../components/seo"

export default function Home(props) {
  let {
    location,
    data: {
      wpPage: {
        title,
        seo,
        language: { slug: lang },
      },
    },
  } = props

  return (
    <Layout location={location} title={parse(title)}>
      <SEO bodyClasses="page home" lang={lang} seo={seo} />
      <HeroAr />
      <AboutAr />
      <LocationAr />
      <FeaturesAr />
      <ExpressAr />
      <FooterAr location={location} />
    </Layout>
  )
}

export const gql_home = graphql`
  {
    wpPage(databaseId: { eq: 128 }) {
      title
      language {
        slug
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
