import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import Carousel from "./Carousel"
import CareersAr from "./Forms/CareersAr"
import LeasingAr from "./Forms/LeasingAr"
import MediaAr from "./Forms/MediaAr"

const ExpressAr = () => {
  let [activeLink, setActiveLink] = useState("leasing")

  const handleClick = e => {
    setActiveLink(e.currentTarget.dataset.name)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(databaseId: { eq: 133 }) {
            title
            content
            featuredImage {
              node {
                ...fragmentImage
              }
            }
            ACF_Gallery {
              gallery {
                image {
                  ...fragmentImage
                }
              }
            }
            seo {
              ...WpYoastSEO
            }
          }
        }
      `}
      render={data => {
        let {
          wpPage: {
            // title,
            // content,
            ACF_Gallery: { gallery },
          },
        } = data
        // console.log(gallery)
        return (
          <section id="express">
            <div className="container">
              <div className="row">
                <div className="col-md-12 p-lr-0">
                  <Carousel data={gallery} cssClass="express-carousel" />
                </div>
              </div>
              <div className="row content">
                <div className="col-md-12">
                  <ul className="nav-tabs">
                    <li className={activeLink === "leasing" ? "active" : null}>
                      <button data-name="leasing" onClick={e => handleClick(e)}>
                        اﺳﺘﻔﺴﺎرات اﻻﺳﺘﺌﺠﺎر
                      </button>
                    </li>
                    <li className={activeLink === "careers" ? "active" : null}>
                      <button data-name="careers" onClick={e => handleClick(e)}>
                        فرص عمل
                      </button>
                    </li>
                    <li className={activeLink === "media" ? "active" : null}>
                      <button data-name="media" onClick={e => handleClick(e)}>
                        أخبارنا
                      </button>
                    </li>
                  </ul>
                  <div className="eyebrow underline">ابدِ اهتمامك</div>
                  <div
                    className={`leasing ${
                      activeLink === "leasing" ? "active" : "d-none"
                    }`}
                  >
                    <h2>اﺳﺘﻔﺴﺎرات اﻻﺳﺘﺌﺠﺎر</h2>
                    <div>
                      <LeasingAr />
                    </div>
                  </div>
                  <div
                    className={`careers ${
                      activeLink === "careers" ? "active" : "d-none"
                    }`}
                  >
                    <h2>فرص عمل</h2>
                    <CareersAr />
                  </div>
                  <div
                    className={`express ${
                      activeLink === "media" ? "active" : "d-none"
                    }`}
                  >
                    <h2>أخبارنا</h2>
                    <div>
                      <MediaAr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }}
    ></StaticQuery>
  )
}

export default ExpressAr
