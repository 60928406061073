import Axios from "axios"
import { Formik } from "formik"
import React from "react"
import * as Yup from "yup"

export default class CareersAr extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      success: false,
      error: false,
      message: "",
    }
  }

  render() {
    const validationSchema = Yup.object().shape({
      Name: Yup.string()
        .min(3, "Must be at least ")
        .max("50", "Too long to be a name!")
        .required("Must enter a name"),
      Email: Yup.string()
        .email("Must be a valid email address")
        .max("50", "Too long to be an email!")
        .required("Must enter an email address"),
      Phone: Yup.string()
        .min(7, "Must be at least ")
        .max("15", "Too long to be a number!")
        .required("Must enter a phone"),
      Message: Yup.string()
        .min(3, "Must be at least ")
        .max("500", "Too long to be a message!")
        .required("Must enter a message"),
    })

    return (
      <Formik
        initialValues={{
          Name: "",
          Phone: "",
          file: "",
          Email: "",
          Message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (this.state.success === true) {
            // console.log("prevented");
            return
          }
          setSubmitting(true)
          // console.log(values);
          // console.log("submitting");
          let bodyFormData = new FormData()
          bodyFormData.set("Name", values.Name)
          bodyFormData.set("Phone", values.Phone)
          bodyFormData.append("file", values.file)
          bodyFormData.set("Email", values.Email)
          bodyFormData.set("Message", values.Message)

          Axios.post(
            `${process.env.WORDPRESS_FORMS}/wp-json/contact-form-7/v1/contact-forms/170/feedback`,
            bodyFormData
          )
            .then(res => {
              this.setState({
                message: res.data.message,
              })
              if (res.data.status === "mail_sent") {
                this.setState({
                  success: true,
                  message: "Thanks, we have received your message.",
                })
                resetForm()
              } else {
                this.setState({
                  error: true,
                })
              }
            })
            .catch(err => {
              this.setState({
                success: false,
                error: true,
              })
            })
          setSubmitting(false)
        }}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleBlur,
          touched,
          errors,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="contactus" onSubmit={handleSubmit}>
            {/* {JSON.stringify(values)} */}
            <div className="row no-gutters">
              <div className="col-md-12">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Name && errors.Name ? "is-invalid" : null
                    }`}
                    type="text"
                    name="Name"
                    id="name"
                    placeholder="الاسم بالكامل *"
                    onChange={handleChange}
                    value={values.Name}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Email && errors.Email ? "is-invalid" : null
                    }`}
                    type="email"
                    name="Email"
                    id="email"
                    placeholder="بريد إلكتروني *"
                    onChange={handleChange}
                    value={values.Email}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Phone && errors.Phone ? "is-invalid" : null
                    }`}
                    type="text"
                    name="Phone"
                    id="phone"
                    placeholder="الجوّال *"
                    onChange={handleChange}
                    value={values.Phone}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>

              <div className="col-md-12 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <textarea
                    className={`form-control message ${
                      touched.Message && errors.Message ? "is-invalid" : null
                    }`}
                    cols="30"
                    rows="5"
                    name="Message"
                    id="message"
                    placeholder="الرسالة *"
                    onChange={handleChange}
                    value={values.Message}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-12 mt-3">
                <span className="wpcf7-form-control-wrap border-0">
                  <input
                    id="file"
                    name="file"
                    type="file"
                    className="custom-file-upload"
                    onChange={event => {
                      setFieldValue("file", event.currentTarget.files[0])
                    }}
                  />
                </span>
                <span className="small">
                  ألا يتعدى حجم الملف 2 ميجا بايت، وتُقبل حصراً صيغ: pdf، وdoc
                  وdocx
                </span>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12">
                <button
                  type="submit"
                  aria-label="Submit the form"
                  className="wpcf7-form-control wpcf7-submit btn btn-default ml-5"
                  disabled={isSubmitting}
                >
                  إرسال
                </button>
                {this.state.message.length > 0 && (
                  <span
                    className={`alert alert-${
                      this.state.success ? "success" : "danger"
                    }`}
                  >
                    {this.state.message}
                  </span>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
}
